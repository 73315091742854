import React from 'react';
import { Helmet } from 'react-helmet';

const Visualization = ({ className = '' }) => {
  return (
    <>
      <Helmet>
        <script src="https://public.tableau.com/javascripts/api/viz_v1.js" />
      </Helmet>
      <div className={className}>
        <div className="w-full mx-auto" style={{ height: '35rem' }}>
          <div
            className="tableauPlaceholder"
            id="viz1597130124284"
            style={{ position: 'relative' }}
          >
            <object
              className="tableauViz"
              style={{ display: 'none', width: '100%', height: '100%' }}
            >
              <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
              <param name="embed_code_version" value="3" />
              <param name="site_root" value="" />
              <param name="name" value="WebsiteTables_15971297606940&#47;2017AverageAGIbyState" />
              <param name="tabs" value="no" />
              <param name="toolbar" value="yes" />
              <param
                name="static_image"
                value="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;We&#47;WebsiteTables_15971297606940&#47;2017AverageAGIbyState&#47;1.png"
              />
              <param name="animate_transition" value="yes" />
              <param name="display_static_image" value="yes" />
              <param name="display_spinner" value="yes" />
              <param name="display_overlay" value="yes" />
              <param name="display_count" value="yes" />
              <param name="language" value="en" />
              <param name="filter" value="publish=yes" />
            </object>
          </div>
        </div>
      </div>
    </>
  );
};

export default Visualization;
