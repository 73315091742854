import React from 'react';

export default () => (
  <div>
    <p className="text-xl font-light leading-relaxed mt-4">Facts don&#39;t lie.</p>

    <p className="text-xl font-light leading-relaxed mt-4">
      Data analytics gives facts about your business to increase the efficiency of your resources.
      Your business resources are your equipment, inventory, employees, physical space, website,
      marketing dollars, accounts receivable, etc. And with the increase in technology, data
      analytics has never been easier. To be brief, data analytics is a crossroads between
      accounting, statistics, marketing, production, and business strategy.
    </p>

    <h4 className="text-xl lg:text-2xl font-semibold mt-4">Small business hurdles</h4>

    <p className="text-xl font-light leading-relaxed">
      Disorganization - Too often we see small businesses that are disorganized and don&#39;t know
      where to begin getting organized. The best place to start getting organized is with accounting
      because the ultimate goal of the business is to make money. And if your accounting is bad, you
      don&#39;t know if you are making money or going into debt. Your books need to be clean,
      consistent, and detailed in order to track if business changes you make are improving your
      bottom line. The next place to start is either the sales team or job costing, whichever is
      more important to management. Gathering data about your sales team should help improve sales
      performance. And job costing means to align expenses with their revenue source so that you can
      see the profitability of each segment of your business (think of a builder with two homes
      under construction and knowing on which home they made a profit and on which home they lost
      money).
    </p>

    <p className="text-xl font-light leading-relaxed mt-4">
      Poor business strategy - It is easy for small business owners to get caught in the day-to-day
      tasks and rarely step back to see the big picture. This forces the owners to move from being a
      technician in their business to being an entrepreneur. Data Analytics requires that the owners
      and management take time to decide where they are going and how to get there. Without a solid
      business strategy, your Data Analytics will do little to help your business.
    </p>

    <p className="text-xl font-light leading-relaxed mt-4">
      Overwhelmed - We recommend that small businesses start with a small number of metrics. Choose
      your most important KPIs (key performance indicators) and give it all of your attention. Data
      analytics is most beneficial when a business can make data-driven decisions to improve
      business profit. To be data-driven requires that you have complete trust in the data (how it
      is gathered and how it is interpreted).
    </p>

    <p className="text-xl font-light leading-relaxed mt-4">
      Gathering Data - Gathering data needs to be as automatic as possible. This increases data
      integrity and costs less. There should also be written guidelines that give clarity to what
      the data represents to create reliable data and to avoid confusion. If you don&#39;t measure
      it correctly, you can&#39;t improve it.
    </p>

    <h4 className="text-xl lg:text-2xl font-semibold mt-4">Decision Making</h4>

    <p className="text-xl font-light leading-relaxed">
      One of the best aspects of data analytics is the speed it allows the company to make
      corrections. Notice how each of the following questions requires an immediate response that
      will make the company more profitable by fixing it quickly: Which large client is almost late
      on their payment that needs to be called by an executive today? Which employees are
      underperforming and need more training now? Why did our website not produce any leads this
      morning?
    </p>

    <p className="text-xl font-light leading-relaxed mt-4">
      If data is not being used to make business decisions, you are doing something wrong. Either
      you don’t trust the data, the reports provided are not actionable, or the leadership doesn’t
      understand its importance. If the business is not increasing their bottom line after
      implementing data analytics then they are wasting their time and money.
    </p>

    <p className="text-xl font-light leading-relaxed mt-4">
      At Timp Accounting, we offer data analytics consulting. We help small businesses make the
      shift to becoming a data-driven business with excellent profit margins. So if you&#39;d like
      your business to stop &quot;winging it&quot;, give us a call today to find out how we can
      help.
    </p>
  </div>
);
