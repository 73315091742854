import React from 'react';
import Visualization from './Visualizations/IndividualTaxes';

export default () => (
  <div>
    <p className="text-xl font-light leading-relaxed mt-4">
      The majority of Americans file their taxes using an DIY online tax software like TurboTax, H&R
      Block, or TaxAct. These are great options for taxpayers who have simple returns and who are
      comfortable answering the many tax questions the software will ask. And if your tax return is
      really simple these companies also have free versions of their software.
    </p>

    <p className="text-xl font-light leading-relaxed mt-4">
      At Timp Accounting, we prepare individual tax returns across the country for small business
      owners, rental property owners, and other taxpayers who don&#39;t want to do it themselves.
      See below for an interactive map (not visible on mobile view) showing the average Adjusted
      Gross Income (AGI) for each state (not adjusted for buying power). We utilize the convenience
      of technology and combine it with a personal touch. No appointment needed! Our process is
      simple:
    </p>

    <ol className="list-decimal list-inside mt-2 ml-8 text-xl font-light leading-relaxed mt-2">
      <li>
        Answer our Yes/No online Tax Questionnaire that helps us know what items we need for your
        tax return. If you don&#39;t know the answer, you can leave it blank.
      </li>
      <li>
        Upload copies of your tax documents (W-2s, interest income statements, mortgage interest
        statements, student loan interest expense, etc) to our{' '}
        <a href="https://timpaccounting.sharefile.com/" target="_blank" rel="noopener noreferrer">
          ShareFile
        </a>{' '}
        portal.
      </li>
      <li>
        We start preparing your tax return and will call or email you with any questions that we
        have (missing documents, tax saving opportunities, etc).
      </li>
      <li>
        We will send you a completed draft copy of your tax return for your review and to give you a
        chance to ask us any questions.
      </li>
      <li>If everything looks good, we will then send you an electronic signature request.</li>
      <li>
        And lastly, your return is e-filed by us and all documents are saved password free on{' '}
        <a href="https://timpaccounting.sharefile.com/" target="_blank" rel="noopener noreferrer">
          ShareFile
        </a>{' '}
        for future access.
      </li>
    </ol>

    <p className="text-xl font-light leading-relaxed mt-4">
      Our goal is to give you the highest tax refund possible - guaranteed! We get a lot of
      satisfaction from making taxes as simple as possible for our clients and saving them a lot of
      money. You can trust that your return will be done right. Call or email us today!
    </p>

    <Visualization className="w-4/6 mx-auto hidden md:block" />
  </div>
);
