import Accounting from '../components/services/Accounting';
import BusinessTaxes from '../components/services/BusinessTaxes';
import CostSegregation from '../components/services/CostSegregation';
import DataAnalytics from '../components/services/DataAnalytics';
import IndividualTaxes from '../components/services/IndividualTaxes';
import RDTaxCredit from '../components/services/R&DTaxCredit';

export default [
  {
    title: 'Business Taxes',
    landingSummary:
      'Tax planning and tax return preparation for businesses. This is our expertise.',
    slug: 'business-taxes',
    component: BusinessTaxes
  },
  {
    title: 'R&D Tax Credit',
    landingSummary:
      'An R&D tax credit is complex, but can save you thousands of dollars. Do you qualify?',
    slug: 'rd-tax-credit',
    description: '',
    component: RDTaxCredit
  },
  {
    title: 'Cost Segregation',
    landingSummary:
      'Own real estate? You may qualify to accelerate your depreciation to save money now.',
    slug: 'cost-segregation',
    component: CostSegregation
  },
  {
    title: 'Individual Taxes',
    landingSummary: "Hate taxes? We'll make sure you're getting the tax refund you deserve.",
    slug: 'individual-taxes',
    component: IndividualTaxes
  },
  {
    title: 'Accounting',
    landingSummary:
      'Everything from answering simple questions to part-time CFO services. We got you covered.',
    slug: 'accounting',
    component: Accounting
  },
  {
    title: 'Data Analytics',
    landingSummary:
      'Want instant data to improve business decisions? This is easier than you think.',
    slug: 'data-analytics',
    component: DataAnalytics
  }
];
