import React from 'react';
import Visualization from './Visualizations/R&DTaxCredit';

export default () => (
  <div>
    <h4 className="text-xl lg:text-2xl font-semibold leading-tight mt-4 mb-2">
      What is R&amp;D Tax Credit?
    </h4>
    <p className="text-xl font-light leading-relaxed">
      The Research and Development Tax Credit is a credit for taxpayers who develop, design,
      enhance, or improve products, formulas, software, or processes. This credit is intended to
      support companies that pursue innovation. It is one of the best ways to reduce a
      business&#39;s tax liability because{' '}
      <a
        href="https://www.hrblock.com/tax-center/filing/credits/difference-between-tax-deduction-and-tax-credit/"
        target="_blank"
        rel="noopener noreferrer"
      >
        tax credits
      </a>{' '}
      are dollar for dollar savings. For example, if your business qualifies for a $100,000 R&amp;D
      Tax Credit, you literally just saved $100,000 in taxes! And since the R&amp;D Tax Credit works
      as a percentage of qualified expenses, there is no limit on how much money you can save.
    </p>

    <h4 className="text-xl lg:text-2xl font-semibold leading-tight mt-8 mb-2">
      Qualification requirements
    </h4>
    <p className="text-xl font-light leading-relaxed">
      The first step in calculating the R&amp;D tax credit is to determine if your activity
      qualifies for the credit. And the activity must pass each of the following 4-part test.
    </p>
    <ol className="list-decimal list-inside mt-2 ml-8 text-xl font-light leading-relaxed">
      <li>Technical uncertainty</li>
      <li>Process of experimentation</li>
      <li>Technological in nature</li>
      <li>Qualified purpose</li>
    </ol>

    <h4 className="text-xl lg:text-2xl font-semibold leading-tight mt-8 mb-2">
      What expenses qualify?
    </h4>
    <p className="text-xl font-light leading-relaxed">
      Step two is gathering expenses, support and documentation for the credit. Qualified R&amp;D
      expenses (QREs) are employee wages, contract labor, and supplies that are directly connected
      with the R&amp;D activity. This credit is technically called &quot;
      <a
        href="https://www.law.cornell.edu/uscode/text/26/41"
        target="_blank"
        rel="noopener noreferrer"
      >
        Credit for increasing research activities
      </a>
      &quot;. Thus, a taxpayer will only obtain a credit if their QREs are higher than previous year
      expenses. The documentation is the most important part of gathering this information to
      support the expense and is best to be gathered in real-time.
    </p>

    <h4 className="text-xl lg:text-2xl font-semibold leading-tight mt-8 mb-2">IRS requirements</h4>
    <p className="text-xl font-light leading-relaxed">
      The IRS likes auditing taxpayers who take large R&amp;D tax credits because if they find an
      error, they can collect a lot of taxes. So if your R&amp;D tax credit is small then you
      probably don&#39;t need a full R&amp;D Tax Credit report completed by the tax accountant. This
      report is not required to be sent to the IRS unless the taxpayer is selected for an audit. The
      report would detail why the activity qualifies for the credit, what expenses were used in the
      calculation, tax law supporting the credit, and calculation results. So step three is
      completing the calculation and the report (if needed).
    </p>

    <h4 className="text-xl lg:text-2xl font-semibold leading-tight mt-8 mb-2">Summary</h4>
    <p className="text-xl font-light leading-relaxed">
      The R&amp;D tax credit is complicated each step in the process, but the tax savings are worth
      the extra work - especially when the business resides in a state that gives an additional
      R&amp;D tax credit. Below is an interactive graph (not visible on mobile view) showing how
      much money taxpayers saved in 2014 using the R&amp;D tax credit separated by industry (
      <a
        href="https://www.irs.gov/statistics/soi-tax-stats-corporation-research-credit"
        target="_blank"
        rel="noopener noreferrer"
      >
        data
      </a>{' '}
      is only shown for Corporations). Call or email us today to see if your company qualifies for
      the R&amp;D tax credit.
    </p>
    <Visualization className="w-4/6 mx-auto hidden md:block" />
  </div>
);
