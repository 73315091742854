import React from 'react';

export default () => (
  <div>
    <h4 className="text-xl lg:text-2xl font-semibold mt-4 mb-2">The Game of Business</h4>

    <p className="text-xl font-light leading-relaxed">
      Business is seen by many people as the{' '}
      <a
        href="https://www.entrepreneur.com/article/274838"
        target="_blank"
        rel="noopener noreferrer"
      >
        ultimate sport
      </a>
      . And just as most sports, business requires both offense and defense. Offense in business is
      everything related to generating more sales. Defense in business is everything related to
      reducing expenses. Both are required for a business to be profitable.
    </p>

    <p className="text-xl font-light leading-relaxed mt-4">
      Successful business owners are often offense minded because they are great at figuring out how
      to get more sales. But as any sports fanatic will tell you,{' '}
      <a href="https://www.azquotes.com/quote/698438" target="_blank" rel="noopener noreferrer">
        defense wins championships
      </a>
      . Accountants are the defense for a business because they keep the business organized
      particularly with finances. Without a good accountant, your business might make a lot of
      money, but you won&#39;t keep as much as you should (think of a high scoring game where you
      only win by 1). Thus the most important characteristics of a good accountant are that they are
      well organized and good at saving money.
    </p>

    <h4 className="text-xl lg:text-2xl font-semibold mt-8 mb-2">Do I Have to?</h4>

    <p className="text-xl font-light leading-relaxed">
      For many of our small business clients, their business is too small or simple to necessitate
      an accountant. They just need help filing their income tax returns. For these clients
      financial accounting is a necessary evil that is only completed because they need to file a
      tax return each year. This is often completed by downloading the transactions from the
      business bank account and organizing them in Excel to create a P&amp;L. We do this frequently
      for clients.
    </p>

    <p className="text-xl font-light leading-relaxed mt-4">
      For a more complex business, we recommend using an accounting software to help you stay
      organized. The purpose of an accounting software is to save you or your accountant time - this
      is done through automation and syncing with your bank and credit card accounts. For this
      purpose we recommend to our clients as it is relatively cheap, can be accessed anywhere and
      has several time saving features.
    </p>

    <p className="text-xl font-light leading-relaxed mt-4">
      At Timp Accounting, we offer accounting services anywhere from &quot;just get it done for the
      tax return&quot; to part-time CFO work who oversees other staff accountants, creates and
      documents accounting procedures, creates monthly financial statements, etc. Call or email us
      today to give your defense a boost!
    </p>
  </div>
);
