import React from 'react';
import { Link } from 'gatsby';
import Button from './Button';

export default () => (
  <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
    <h3 className="text-5xl font-semibold">Let&apos;s work together</h3>
    <p className="mt-8 text-xl font-light">
      Check out our other pages to learn more about who we are, what we do, and who we serve. You
      won’t be disappointed.
    </p>
    <p className="mt-8">
      <Link to="/contact">
        <Button size="xl">Contact Us</Button>
      </Link>
    </p>
  </section>
);
