import React from 'react';

export default () => (
  <div>
    <h4 className="text-xl lg:text-2xl font-semibold leading-tight mt-4 mb-2">
      What is a Cost Segration?
    </h4>
    <p className="text-xl font-light leading-relaxed">
      A Cost Segregation is the act of splitting a real estate property into separate asset classes
      (land, building, personal property, land improvements, etc) in order to depreciate parts of
      the building faster. The best time to complete a Cost Segregation is the first tax year in
      which you purchase a building. However, if you&#39;ve owned a property for a few years and
      would now like to take advantage of a Cost Segregation, you still have this option but an
      additional form will need to be filed with your tax return (Form 3115 - Application for Change
      in Accounting Method).
    </p>

    <h4 className="text-xl lg:text-2xl font-semibold leading-tight mt-8 mb-2">Tax benefits</h4>
    <p className="text-xl font-light leading-relaxed">
      The tax benefits related to a Cost Segregation is all related to{' '}
      <a
        href="https://www.youtube.com/watch?v=733mgqrzNKs"
        target="_blank"
        rel="noopener noreferrer"
      >
        Time Value of Money
      </a>
      . A building can ultimately only depreciate the cost of the building (land typically does not
      depreciate). Thus a residential rental building that would be depreciated evenly by the IRS
      over 27.5 years without a Cost Segregation, could accelerate the depreciation in the early
      years with less depreciation in the later years with a Cost Segregation. This increases your
      Time Value of Money because you save more money in taxes now. As an easy example: would you
      rather have $10,000 today or $10,000 twenty years in the future?
    </p>

    <h4 className="text-xl lg:text-2xl font-semibold leading-tight mt-8 mb-2">Residential rental property</h4>
    <p className="text-xl font-light leading-relaxed">
      For buildings that cost under $1 million, our clients love what we call a mini cost
      segregation. This mini cost segregation splits a building into its separate asset classes as
      best as we can using limited resources. And since this is a &quot;mini&quot; cost segregation,
      our price is low which gives our clients the highest Time Value of Money possible. The risk is
      that the IRS disagrees with our asset valuations and we may want to complete a Full Cost
      Segregation after the IRS has selected your tax return for an audit.
    </p>

    <h4 className="text-xl lg:text-2xl font-semibold leading-tight mt-8 mb-2">Commercial property</h4>
    <p className="text-xl font-light leading-relaxed">
      For buildings that cost over $1 million, our clients generally prefer a Full Cost Segregation
      study. This gives a more detailed Cost Segregation that will give greater audit protection
      because the documentation used to substantiate the asset valuations would be created during
      the study. This would include inspecting the facility, taking several pictures, using
      nationally accepted cost estimating databases, and creating a clean report that can be handed
      to the IRS in the event of an audit.
    </p>

    <h4 className="text-xl lg:text-2xl font-semibold leading-tight mt-8 mb-2">Conclusion</h4>
    <p className="text-xl font-light leading-relaxed">
      Taxpayers save money by utilizing this Cost Segregation strategy because of the time value of
      money. Call or email us today to discuss how we can save you additional money in taxes this
      year!
    </p>
  </div>
);
