import React from 'react';

export default () => (
  <div>
    <p className="text-xl font-light leading-relaxed mt-4">
      Business taxes can be complicated to prepare, but we try to keep it as simple as possible. Our
      process is to have you to send us a copy of your business financials, we start preparing your
      tax return, and then we will ask you clarifying questions and discuss tax saving opportunities
      via email or phone. We will then send you a copy of your return to review and ask us
      questions. That&#39;s it! Our process is simple, but sometimes our clients like to challenge
      us. The most common challenge is messy accounting records.
    </p>

    <p className="text-xl font-light leading-relaxed mt-4">
      The type of income tax return that a business needs to file depends on the entity structure,
      IRS elections, and business ownership. There are pros and cons to each entity type that we
      often discuss with clients during tax strategy meetings. We currently prepare the following
      tax returns for our clients:
    </p>
    <ul className="list-disc list-inside mt-2 ml-8 text-xl font-light leading-relaxed">
      <li>Partnerships</li>
      <li>S Corporations</li>
      <li>C Corporations</li>
      <li>Foundations</li>
      <li>Charitable Organizations</li>
    </ul>

    <p className="text-xl font-light leading-relaxed mt-6">
      A Limited Liability Company (LLC) can be filed in three different ways depending on the
      ownership. When there is just one owner of an LLC it is considered as disregarded for taxes
      and is shown on Schedule C of the owner&#39;s personal tax return. An LLC that is owned by two
      or more people usually has to file a partnership tax return. But any LLC that elects to be
      taxed as an S-Corporation, would need to file an S-Corporation tax return.
    </p>

    <p className="text-xl font-light leading-relaxed mt-4">
      Call or email us today to discuss your business tax situation and how we can help!
    </p>
  </div>
);
